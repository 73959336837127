
export const IndexData = {
     about: {
          heading : <>We will <span className="gt">multiply</span> your funds <span className="gt">unlimited </span><small>without any risks</small></>,
          cards: [
               {
                    head : "",
               }
          ],
     }

}