import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { logo, title, websiteUrl } from '../Config/Config';
import { AddLinkRounded, CloseRounded } from '@mui/icons-material';


const Header = (props) => {

     const [isScrolled, setIsScrolled] = useState(false);
     const [open, setOpen] = useState(false);
     const onOpenModal = () => setOpen(true);
     const onCloseModal = () => setOpen(false);
     const [openWallet, setOpenWallet] = useState(false);
     const onOpenWallet = () => setOpenWallet(true);
     const onCloseWallet = () => setOpenWallet(false);

     useEffect(() => {
          const handleScroll = () => {
               if (window.scrollY >= 100) {
                    setIsScrolled(true);
               } else {
                    setIsScrolled(false);
               }
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, []);



     const headerContent = [
          { to: '#about', className: 'scrollto', content: 'About us', onClick: props.scrollToAbout },
          { to: '#investments', className: 'scrollto', content: 'Investments', onclick: props.scrollToinvestments },
          { to: '', className: 'colorchange', content: "Contest" },
          { to: '#', className: 'mainlogo', content: <img src={logo} alt={title + '-Logo'} /> },
          { to: '#', className: '', content: "Make deposit" , onClick: onOpenModal },
          { to: '#', className: '', content: "Wallet statistic" , onClick : onOpenWallet  },
     ]

     return (
          <>
               <header className={isScrolled ? '__home-header scroll-header' : '__home-header'}>
                    <div className="__header-continer">
                         <nav>
                              {headerContent.map((item, index) => (
                                   <Link
                                        key={index}
                                        to={item.to}
                                        className={item.className}
                                        onClick={item.onClick} >
                                        {item.content}
                                   </Link>
                              ))}
                         </nav>
                    </div>
               </header>

               <Modal open={open} onClose={onCloseModal} center
                    classNames={{
                         modal: 'modal-sec',
                         overlay: 'modal-overly-sec'
                    }} >
                    <div className='modal-contant'>
                         <h4>Make New Deposit</h4>

                         {/* start up modal data.....  line 67 to 76 */}
                         <form className="form">
                              <input type="text" placeholder="Connect with your wallet app..." readonly="" />
                              <div className="modalref">
                                   Connect with your <span> TrustWallet</span> / <span> MetaMask</span>browser extension, or <span> TokenPocket</span> / <span> Banko</span>mobile app. After connection you will get opportunity to make deposit (reload this page).
                                   <p> Please, contact our support team if you cant connect.</p>
                              </div>
                         </form>

                         <div className='dashmodal'></div>

                         <div className='__deposit-sec'>
                              <div id="registered" className='registered'>

                                   <div id="replace_deposit" className='replace_deposit'>
                                        <label>Specify deposit <img src={logo} alt="" /> DAO amount here:</label>
                                        <div className='__inputara'>
                                             <div className='__inputs'>
                                                  <input className="trxAmount1" type="text" placeholder="1000" name="amtinvest" id="amtinvest" />
                                             </div>

                                             <div className='__inputs'>
                                                  <input className="trxAmount1" type="text" placeholder="User address" id="uaddress" name="uaddress" />
                                             </div>

                                             <div className='__inputs'>
                                                  <input className="trxAmount1" type="password" maxlength="6" placeholder="Security Pin" id="securitypin" name="securitypin" />
                                             </div>
                                        </div>

                                        <div className='__btns'>
                                             <button type="button" className="maindescbut investButton1"> CANCEL </button>
                                             <button type="button" className="maindescbut investButton1"> Deposit Now </button>
                                        </div>

                                        <div className="userRefererDiv mt-3">
                                             <i className="fal fa-user-friends"></i> Your upline partner wallet:
                                             <span className="userReferer">No upline</span>
                                        </div>
                                   </div>

                                   <div className='dashmodal'></div>


                                   <div className='__card-sec'>
                                        <div className='row'>
                                             <div className='col-md-6'>
                                                  <div className='card-qe'>
                                                       <p>split Wallet</p>

                                                       <div className='__balance'>
                                                            <img src={logo} alt="" />
                                                            <span> 4,343,23</span>
                                                       </div>

                                                       <div className='check-it'>
                                                            <h6>click to view:</h6>
                                                            <button type='button' className='btn'> View History</button>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className='col-md-6'>
                                                  <div className='card-qe'>
                                                       <p>split Wallet</p>

                                                       <div className='__balance'>
                                                            <img src={logo} alt="" />
                                                            <span> 4,343,23</span>
                                                       </div>

                                                       <div className='check-it'>
                                                            <h6>click to view:</h6>
                                                            <button type='button' className='btn'> View History</button>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className='col-12'>
                                                  <div className='table-qe'>
                                                       <div className='__close__btn'>
                                                            <button type='button' className='btn-close'>
                                                                 <CloseRounded />
                                                            </button>
                                                       </div>
                                                       <table class="table table-striped table-dark">
                                                            <thead>
                                                                 <tr>
                                                                      <th>Firstname</th>
                                                                      <th>Lastname</th>
                                                                      <th>Email</th>
                                                                 </tr>
                                                            </thead>
                                                            <tbody>
                                                                 <tr>
                                                                      <td>John</td>
                                                                      <td>Doe</td>
                                                                      <td>john@example.com</td>
                                                                 </tr>
                                                                 <tr>
                                                                      <td>Mary</td>
                                                                      <td>Moe</td>
                                                                      <td>mary@example.com</td>
                                                                 </tr>
                                                                 <tr>
                                                                      <td>July</td>
                                                                      <td>Dooley</td>
                                                                      <td>july@example.com</td>
                                                                 </tr>
                                                            </tbody>
                                                       </table>

                                                       <div className='__pagination'>
                                                            <ul class="pagination pagination-sm">
                                                                 <li class="page-item"><Link class="page-link" to="#">Previous</Link></li>
                                                                 <li class="page-item"><Link class="page-link" to="#">1</Link></li>
                                                                 <li class="page-item"><Link class="page-link" to="#">2</Link></li>
                                                                 <li class="page-item"><Link class="page-link" to="#">3</Link></li>
                                                                 <li class="page-item"><Link class="page-link" to="#">Next</Link></li>
                                                            </ul>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>

                                   </div>


                              </div>

                         </div>

                    </div>
               </Modal>

               <Modal open={openWallet} onClose={onCloseWallet} center
                    classNames={{
                         modal: 'modal-sec',
                         overlay: 'modal-overly-sec'
                    }} >
                    <div className='modal-contant'>
                         <h4>Wallet Statistic</h4>

                         {/* <form className="form">
                              <input type="text" placeholder="Connect with your wallet app..." readonly="" />
                              <div className="modalref">
                                   Connect with your <span> TrustWallet</span> / <span> MetaMask</span>browser extension, or <span> TokenPocket</span> / <span> Banko</span>mobile app. After connection you will get opportunity to make deposit (reload this page).
                                   <p> Please, contact our support team if you cant connect.</p>
                              </div>

                              <button type='button' className="maindescbut"><i className="far fa-bullhorn"></i> Promo materials</button>

                         </form> */}

                         <div className='__wallet-statistic'>
                              <div className='__address'>
                                   <p className='sub-heading'> Your <img src={logo} alt="logo" /> DAO wallet address:</p>

                                   <input type="text" placeholder="Connect with your wallet app..." readonly="" />

                                   <div class="modalref">

                                        <AddLinkRounded /> Your referral link:
                                        <h6 class="reflink">
                                             <Link to={websiteUrl} target='_blank'>
                                                  {websiteUrl}
                                             </Link>
                                        </h6>

                                        <small>- Share this link with your partners to get free core dao</small><br />

                                   </div>
                              </div>

                              <div className='wallet-card-sec'>
                                   <div className='row'>
                                        <div className='col-md-6'>
                                             <div className='card-qe'>
                                                  <p>Total free core:</p>

                                                  <div className='__balance'>
                                                       <img src={logo} alt="" />
                                                       <span> 4,343,23</span>
                                                  </div>

                                                  <div className='check-it'>
                                                       <h6>request to get:</h6>
                                                       <button type='button' className='btn'> CLAIMED</button>
                                                  </div>
                                             </div>
                                        </div>

                                        <div className='col-md-6'>
                                             <div className='card-qe'>
                                                  <p>Total free core:</p>

                                                  <div className='__balance'>
                                                       <img src={logo} alt="" />
                                                       <span> 4,343,23</span>
                                                  </div>

                                                  <div className='check-it'>
                                                       <h6>request to get:</h6>
                                                       <button type='button' className='btn'> CLAIMED</button>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className='__address mt-3'>
                                   <p className='sub-heading'> Your referral link:</p>

                                   <input type="text" placeholder="Connect with your wallet app..." value={websiteUrl} readonly="" />

                                   <div className='btnsqe'>

                                        <button type='button' className='btn'> copy referral link</button>
                                        <button type='button' className='btn'> security pass code</button>
                                   </div>

                              </div>

                         </div>
                    </div>
               </Modal>

          </>
     );
}

export default Header;
