import React from 'react';
import { logo, title, websiteUrl } from '../Config/Config';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="__home-footer">
            <div className="__container">
                <div className="footerwrap">
                    <div className="footblock">
                        <Link to="#" className="footlogo"><img src={logo} alt="" /></Link>
                        Copyrights:<br />
                        {title} LTD | <Link to="#">{websiteUrl}</Link><br />
                        Blockchain investment platform<br />
                        2024 © All Rights Reserved
                    </div>
                    <div className="footblock">
                        Smart-contract address: <br/>
                        <Link to="#" target="_blank">0x3206867F6b9e50d651A6ce50060283BCa0aA507C</Link><br /><br />
                        Powered by <img src={logo} className="footimg" alt="" /> {title} blockchain
                    </div>
                    <div className="footblock">
                        <button type='button' className="maindescbut mb-3" ><i className="far fa-donate"></i>Make Deposit</button>

                        <button type='button' className="maindescbut"><i className="far fa-wallet"></i> Wallet statistic</button>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
