import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import './Assets/Scss/style.scss';
import Index from './Public/Index';

function App() {



  return (
    <>

      <Router>
        <Routes>

          <Route>
            <Route path='/' element={<Index />} />

          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
