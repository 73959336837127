import { CheckCircleOutline } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { shorttitle, title } from '../../Config/Config';

const Banner = () => {

     const [offsetX, setOffsetX] = useState(0);
     const [offsetY, setOffsetY] = useState(0);
   
     const handleMouseMove = (e) => {
       const { clientX, clientY, currentTarget } = e;
       const { offsetWidth, offsetHeight } = currentTarget;
   
       // Calculate the position of the cursor relative to the banner
       const xPos = (clientX / offsetWidth) - 0.5; // -0.5 to 0.5
       const yPos = (clientY / offsetHeight) - 0.5; // -0.5 to 0.5
   
       // Adjust the offset values based on cursor position
       setOffsetX(xPos * 30); // Adjust the multiplier to control the movement intensity
       setOffsetY(yPos * 30);
     };

     return (
          <>
               <div className="__banner" onMouseMove={handleMouseMove}>
                    <div className="__container">
                         <div className="mainscreen">
                              <div className="mainbackwrap Left__section">
                                   <div data-offset="30" className="mainphone parallax"  style={{ transform: `translate(${offsetX}px, ${offsetY}px) scale(1.6) rotate(14deg)` }}></div>
                                   <div className="dash1"></div>
                                   <div className="dash2"></div>
                                   <div className="dash3"></div>
                                   <div className="dash4"></div>
                                   <div className="perfection">perfection in details</div>
                              </div>

                              <div className="mainbackwrap">
                                   <div className="mainbackdesc">
                                        <h1 className='h-1'>Reliable investments with <span>{title}</span></h1>
                                        <h6>Get <span>free 10 </span> {shorttitle} Rewards by free registration and also get benifit free refferal program up to 11 Levels</h6>
                                        <div className="maindesc">
                                             <div className="maindesctext">
                                                  <span>  <CheckCircleOutline/> Blockchain decentralized and anonymous platform</span>
                                                  <span> <CheckCircleOutline/> Totally secure income based on <img src="images/core.png" alt="" /> DAO smart-contract</span>
                                                  <span> <CheckCircleOutline/> Smart-contract verified and audited by independent company</span>

                                                  <Link id="invsttbutton" to="#" className="maindescbut">Please wait!</Link>

                                                  <div className="maindesclink">
                                                       <Link target="_blank" to="#" className="maindesclink"><i className="fas fa-print-search"></i> Check Current State {title}  smart-contract</Link>

                                                  </div>
                                                  <div className="maindesclink">

                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default Banner;
