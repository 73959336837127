import React from 'react';
import { logo, shorttitle } from '../../Config/Config';
import icon3 from '../../Assets/Images/icostep3.png'
import { Link } from 'react-router-dom';

const Roadmap = (props) => {
     return (
          <>
               <div className="__roadMap" ref={props.investmentsRef} id="investments">
                    <div className="__container">
                         <div className="dashtop"></div>
                         <h2>How to work with platform<small>simple <span className="gt">3 steps</span> to get earnings</small></h2>
                         <div className="stepsline">
                              <div className="bigstepline"></div>
                              <div className="bigsteplinecic1"></div>
                              <div className="stepslinewrap">
                                   <h3>Step #1: Sign Up</h3>
                                   <div className="stepsblock">
                                        <h4>Get <img src={logo} alt="" /> {shorttitle} (DAO)</h4>
                                        We recommend to use: <b>Token Pocket</b> / <b>MetaMask</b> browsers extensions.<br />
                                        You can get DAO coins via popular exchangers.
                                        <Link to="#" data-remodal-target="tron" className="maindescbut">How to get DAO</Link>
                                   </div>
                                   <div className="stepsblock">
                                        <h4>Must have some <img src={logo} alt="" /> DAO for fee</h4>
                                        Empowering users through decentralized design, distribution, and direction. <b>#{shorttitle}DAO</b> is the 3D blockchain that is changing the way you interact with crypto.
                                        <Link to="#" className="maindescbut">Sign Up Now</Link>
                                   </div>
                              </div>


                         </div>

                         <div className="stepsline">
                              <div className="bigsteplinecic1"></div>
                              <div className="stepslinewrap">
                                   <div className="icosteps2 sdfsdf">
                                        <img src={icon3} alt="" />
                                   </div>
                                   <form className="maincontform">
                                        <label>Your <img src="images/core.png" alt="" /> DAO wallet address:</label>

                                        <input type="text" className="trxWallet authTrue" value="Wallet address" style={{ display: 'none' }} readonly="" />

                                        <input type="text" className="trxWallet authFalse" placeholder="Connect with your wallet app..." readonly="" />
                                        <Link to="#" data-remodal-target="wallet" className="maindescbut">Check wallet statistics</Link>
                                   </form>
                              </div>

                              <div className="stepslinewrap stepslinewrapright">
                                   <h3>Step #2: Get Dao earnings</h3>
                                   <div className="stepsblock stepsblockfull">
                                        <h4>Get your <img src="images/core.png" alt="" /> DAO every moment</h4>
                                        Free Refferal {shorttitle} Rewards Programme
                                        .<br /><br />
                                        Get free 10 {shorttitle} Coin Reward just by free ragistration and also get benifit free refferal program up to 11 Levels

                                        <u>Additional earnings with this program:</u><br />
                                        Direct team's  Reward: <b> You will get benifit of 1 DAO from every level up to 11 Levels</b><br />


                                        Self - <b>10 {shorttitle}</b><br />

                                        Each Level  = <b>1 DAO</b>







                                        <Link to="#" className="maindescbut">Your wallet statistics</Link>
                                   </div>
                              </div>

                         </div>

                         <div className="stepsline">
                              <div className="bigsteplinecic1"></div>
                              <div className="stepslinewrap">
                                   <h3>
                                        Step #3: Get Benifit of F50
                                   </h3>
                                   <div className="stepsblock stepsblockfull2">
                                        <h4>Request <img src="images/core.png" alt="" />  F50 Program</h4>
                                        <i className="far fa-check-circle"></i>  Get a Huge Team in  <b>F50 Program</b><br /><br />
                                        <i className="far fa-check-circle"></i> F50 Program is Designed and Developed by Unique and Smart Way from which You will get a Huge Team<br /><br />
                                        <i className="far fa-check-circle"></i> You will Receive again and again Earnings from the Team upto 3.33% Daily of your investment
                                   </div>
                              </div>

                         </div>
                         <div className="stepsline">
                              <div className="bigsteplinecic1"></div>
                              <div className="stepslinewrap stepslinewrapright">
                                   <h3>
                                        Step #4: Get Benifit of Magic Income
                                   </h3>
                                   <div className="stepsblock stepsblockfull2">
                                        <h4><img src="images/core.png" alt="" />  Magic Income Program</h4>
                                        <i className="far fa-check-circle"></i>  Magic Income will be distributed Weekly Basis according of your Front Business Legs.<br /><br />
                                        <i className="far fa-check-circle"></i>From first leg 0%<br /><br />

                                        <i className="far fa-check-circle"></i>From second leg 5%<br /><br />

                                        <i className="far fa-check-circle"></i>From third leg 10%<br /><br />

                                        <i className="far fa-check-circle"></i>From 4th leg 15%<br /><br />

                                        <i className="far fa-check-circle"></i>From 5th and above Legs 20%.<br /><br />
                                        <i className="far fa-check-circle"></i>There is no caping in magic income.<br /><br />
                                        <i className="far fa-check-circle"></i>It will be distributed up to unlimited Depth of Levels.<br /><br />
                                   </div>
                              </div>
                         </div>

                         <div className="stepsline">
                              <div className="bigsteplinecic1"></div>
                              <div className="stepslinewrap w-100">
                                   <h3>
                                        Step #5: Get Benifit of Pre Launching Bonanza
                                   </h3>
                                   <div className="stepsblock stepsblockfull2">
                                        <h4><img src="images/core.png" alt="" />  Pre Launching Program</h4>
                                        <i className="far fa-check-circle"></i>Get 100% Direct income from your Active Direct's Upgraded Package.<br /><br />
                                        <i className="far fa-check-circle"></i>Offer valid for 30 days from your TOP-UP Day.<br /><br />


                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default Roadmap;
