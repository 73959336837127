import React from 'react';
import engImage from '../../Assets/Images/Links/eng2.svg';
import irnImage from '../../Assets/Images/Links/irn2.svg';
import rusImage from '../../Assets/Images/Links/rus2.svg';
import chiImage from '../../Assets/Images/Links/chi25e1f.svg';
import espImage from '../../Assets/Images/Links/esp2.svg';
import araImage from '../../Assets/Images/Links/ara2.svg';
import trkImage from '../../Assets/Images/Links/trk2.svg';
import whatsappImage from '../../Assets/Images/Links/whatsapp.jpg';
import youtubeImage from '../../Assets/Images/Links/youtube.jpg';
import telegramImage from '../../Assets/Images/Links/telegram.jpg';
import facebookImage from '../../Assets/Images/Links/facebook.jpg';
import dailymoImage from '../../Assets/Images/Links/dailymo.jpg';
import twitterImage from '../../Assets/Images/Links/twitter.jpg';
import kooapImage from '../../Assets/Images/Links/kooap.jpg';
import instagramImage from '../../Assets/Images/Links/instagram.jpg';
import threadsImage from '../../Assets/Images/Links/threads.jpg';
import { Link } from 'react-router-dom';


const Links = () => {
     return (
          <>
               <div className="__left-sec">
                    <Link to="#" data-lan="en" className="intohi langwrap langwrap_en">
                         <img src={engImage} alt="English" />
                    </Link>
                    <Link to="#" data-lan="fa" className="intohi langwrap langwrap_fa">
                         <img src={irnImage} alt="Farsi" />
                    </Link>
                    <Link to="#" data-lan="ru" className="intohi langwrap langwrap_ru">
                         <img src={rusImage} alt="Russian" />
                    </Link>
                    <Link to="#" data-lan="zh" className="intohi langwrap langwrap_zh">
                         <img src={chiImage} alt="Chinese" />
                    </Link>
                    <Link to="#" data-lan="es" className="intohi langwrap langwrap_es">
                         <img src={espImage} alt="Spanish" />
                    </Link>
                    <Link to="#" data-lan="ar" className="intohi langwrap langwrap_ar">
                         <img src={araImage} alt="Arabic" />
                    </Link>
                    <Link to="#" data-lan="tr" className="intohi langwrap langwrap_tr">
                         <img src={trkImage} alt="Turkish" />
                    </Link>
               </div>

               <div className="__right-sec">
                    <Link to="#" title="WhatsApp" target="_blank" className="langwrapp langwrap_en">
                         <img src={whatsappImage} alt="WhatsApp" />
                    </Link>
                    <Link to="#" target="_blank" title="YouTube" className="langwrapp langwrap_fa">
                         <img src={youtubeImage} alt="YouTube" />
                    </Link>
                    <Link to="#" target="_blank" title="Telegram" className="langwrapp langwrap_ru">
                         <img src={telegramImage} alt="Telegram" />
                    </Link>
                    <Link to="#" target="_blank" title="Facebook" className="langwrapp langwrap_zh">
                         <img src={facebookImage} alt="Facebook" />
                    </Link>
                    <Link to="#" target="_blank" title="Dailymotion" className="langwrapp langwrap_es">
                         <img src={dailymoImage} alt="Dailymotion" />
                    </Link>
                    <Link to="#" target="_blank" title="X" className="langwrapp langwrap_ar">
                         <img src={twitterImage} alt="X (Twitter)" />
                    </Link>
                    <Link to="#" target="_blank" title="Koo" className="langwrapp langwrap_tr">
                         <img src={kooapImage} alt="Koo" />
                    </Link>
                    <Link to="#" target="_blank" title="Instagram" className="langwrapp langwrap_tr">
                         <img src={instagramImage} alt="Instagram" />
                    </Link>
                    <Link to="#" target="_blank" title="Threads" className="langwrapp langwrap_tr">
                         <img src={threadsImage} alt="Threads" />
                    </Link>
               </div>


          </>
     );
}

export default Links;
