import React from 'react';
import { Link } from 'react-router-dom';
import { IndexData } from '../../allContent/IndexTxt';
import { logo, title } from '../../Config/Config';

const About = (props) => {
     return (
          <>
               <div className="__about" id="about" ref={props.aboutRef}>
                    <div className="__container">
                         <h2> {IndexData.about.heading} </h2>
                         <div className="mainaboutitemsblock">
                              <div className="aboutdashtop"></div>
                              <div className="aboutdashbottom"></div>
                              <div className="aboutdashleft"></div>
                              <div className="aboutdashright"></div>

                              <div className="__kds">
                                   <div className='__img'>
                                        <img src={logo} alt={title + "-logo"} />
                                   </div>

                                   <Link to="#" target="_blank"> See {title} Scan </Link>
                                   <Link to="#" target="_blank"> See {title} Coin at Coin Market Cap</Link>

                                   <h3>Reliability of DAO smart-contract.<br />Scam is impossible!</h3>
                                   Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.
                              </div>

                              <div className='__cards_sd'>

                                   <div className="mainaboutitem">
                                        <h3>Unlimited earnings with always growing rates</h3>
                                        We provide investment conditions with growing percentage, depending on basic interest rate, smart-contract total balance bonus and personal "hold-bonus". Maximal available profit:<br /><b>+100% {title} dao of  referral's daily reward</b>
                                   </div>
                                   <div className="mainaboutitem">
                                        <div className='__img'>
                                             <img src={logo} alt={title + "-logo"} />
                                        </div>
                                        <Link to="#" target="_blank"> See {title} Scan </Link>
                                        <Link to="#" target="_blank"> See {title} Coin at Coin Market Cap</Link>

                                        <h3>Reliability of DAO smart-contract.<br />Scam is impossible!</h3>
                                        Smart-contract technology ensures full safety of all participants funds. Nobody can steal funds, or change contract functions.
                                   </div>

                                   <div className="mainaboutitem">
                                        <h3>Worldwide legal company with professional team</h3>
                                        We are the officially registered company with team of trusted professionals on blockchain market. We are working 24/7 to increase platform popularity and make it truly worldwide.<br /><b>Join us and get your profit!</b>
                                   </div>

                              </div>

                         </div>

                    </div>
               </div>
          </>
     );
}

export default About;
