import React, { useRef } from 'react';
import Banner from '../Components/Homepage/Banner';
import About from '../Components/Homepage/About';
import Roadmap from '../Components/Homepage/Roadmap';
import Links from '../Components/Homepage/Links';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';


const Index = () => {

     const aboutRef = useRef<HTMLDivElement>(null);
     const investmentsRef = useRef<HTMLDivElement>(null);

     const scrollToAbout = () => {
          if (aboutRef.current) {
               aboutRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const scrollToinvestments = () => {
          if (investmentsRef.current) {
               investmentsRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     return (
          <>
               <Header scrollToAbout={scrollToAbout} scrollToinvestments={scrollToinvestments} />
               <div className='__home'>
                    <Links />
                    <Banner />
                    <About aboutRef={aboutRef} />
                    <Roadmap  aboutRefinvestments={investmentsRef}/>
               </div>
               <Footer />
          </>
     );
}

export default Index;
